import React from 'react'

import { Row, Col, Button, Modal, Form, Input } from 'antd'

const CreatePackage = (props) => {
  return (
    <Modal
      open={props.modalVisible}
      onCancel={() => props.closeModal()}
      className="sb-modal"
      width={400}
      destroyOnClose
      centered
      footer={[
        <Row justify="center" key="add-package-modal">
          <Col span={18}>
            <Button className="btn-sb btn-sb-primary w-100" form="add-package" htmlType="submit" loading={props.apiLoading}>
              Бүртгэх
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Form name="add-package" form={props.formRef} onFinish={props.createPackage}>
        <Form.Item
          name="name"
          label="Нэр"
          rules={[
            {
              required: true,
              message: 'Нэр оруулна уу!',
            },
          ]}
        >
          <Input placeholder="Нэр" className="sb-input sb-shadow" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreatePackage
