import React, { useState } from 'react'
import { faUpload, faEdit, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Editor } from '@tinymce/tinymce-react'
import client from '../../../api/client'
import { Row, Col, Table, Button, Input, Upload, Form, Image, message, Checkbox, notification, Modal } from 'antd'
const tableTitleStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: 24,
}
const Banners = (props) => {
  const [editBannerFormRef] = Form.useForm()
  const [addBannerFormRef] = Form.useForm()
  const [bannerContent, setBannerContent] = useState('')
  const [uploadFile, setUploadFile] = useState(null)
  const [isActive, setIsActive] = useState(true)
  const [loading, setLoading] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const addBanner = (values) => {
    if (!uploadFile) notification.warning({ message: 'Баннер зургаа оруулна уу!' })
    else {
      setLoading(true)
      const formData = new FormData()
      formData.append('Name', values.name)
      formData.append('HTMLContent', bannerContent)
      formData.append('isActive', isActive)
      formData.append('Image', uploadFile.originFileObj)
      client
        .post('/api/lookup/banners', formData)
        .then(async (res) => {
          notification.success({
            message: 'Шинэ баннер амжилттай нэмэгдлээ!',
          })
          setBannerContent('')
          setIsActive(false)
          setUploadFile(null)
          addBannerFormRef.resetFields()
          props.fetchBannerList()
          setLoading(false)
        })
        .catch((err) => console.error({ err }))
    }
  }
  const getBanner = (id) => {
    setLoading(true)
    client
      .get(`/api/lookup/banners/${id}`)
      .then(async (res) => {
        setEditModalVisible(true)
        editBannerFormRef.resetFields()
        editBannerFormRef.setFieldsValue({
          id: res.data.id,
          IsActive: res.data.isActive ? ['active'] : [],
          Name: res.data.name,
          HTMLContent: res.data.htmlContent,
        })
      })
      .catch((err) => console.error({ err }))
    setLoading(false)
  }
  const editBanner = () => {
    if (editBannerFormRef.getFieldValue('HTMLContent') === null) {
      editBannerFormRef.setFieldsValue({
        HTMLContent: '',
      })
    }
    editBannerFormRef
      .validateFields()
      .then(async (values) => {
        const formData = new FormData()
        formData.append('Name', values.Name)
        formData.append('HTMLContent', values.HTMLContent.level?.content ? values.HTMLContent.level?.content : values.HTMLContent)
        formData.append('IsActive', values.IsActive.length > 0)
        client
          .put(`/api/lookup/banners/${values.id}`, formData)
          .then(async (res) => {
            notification.success({
              message: 'Баннер амжилттай засагдлаа!',
            })
            editBannerFormRef.resetFields()
            setEditModalVisible(false)
            props.fetchBannerList()
          })
          .catch((err) => console.error({ err }))
      })
      .catch((info) => {
        console.error('Validate Failed:', info)
      })
  }
  const bannerColumns = [
    {
      title: 'Баннер',
      className: 'text-center',
      width: '10%',
      render: (record) => <Image src={record.url} fallback="/favicon-32x32.png" />,
    },
    {
      title: 'Нэр',
      className: 'text-center',
      dataIndex: 'name',
      width: '40%',
    },
    {
      title: 'Идэвхтэй',
      className: 'text-center',
      width: '25%',
      dataIndex: 'isActive',
      render: (record) => (record ? <FontAwesomeIcon icon={faCheckCircle} size="1x" className="mx-2" style={{ color: '#72be4f' }} /> : ''),
    },
    {
      title: 'Үйлдэл',
      className: 'text-center',
      dataIndex: 'id',
      width: '160px',
      render: (record) => (
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Button className="btn-sb btn-sb-primary w-100" onClick={() => getBanner(record)}>
              <FontAwesomeIcon icon={faEdit} size="1x" className="mx-2" />
            </Button>
          </Col>
          <Col span={12}>
            <Button className="btn-sb btn-sb-danger w-100" onClick={() => props.deleteBanner(record)}>
              <FontAwesomeIcon icon={faTrashAlt} size="1x" className="mx-2" />
            </Button>
          </Col>
        </Row>
      ),
    },
  ]
  const uploadProps = {
    name: 'file',
    fileList: uploadFile ? [uploadFile] : [],
    beforeUpload: (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        message.error('Image must smaller than 5MB!')
      }
      return isJpgOrPng && isLt5M
    },
    onChange: (info) => setUploadFile(info.file),
  }
  return (
    <>
      <Col span={14}>
        <Row gutter={[8, 8]}>
          <Col flex="auto" style={tableTitleStyle}>
            <span>Баннерын жагсаалт ({props.bannersInfo.dataCount})</span>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Table
            className="sb-table w-100 mt-3"
            dataSource={props.bannersInfo.tableData}
            columns={bannerColumns}
            rowKey={(record) => record.id}
            locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
            pagination={false}
            loading={props.loading}
          />
        </Row>
      </Col>
      <Col span={10}>
        <div className="sb-panel">
          <div className="sb-panel-header">Шинэ баннер бүртгэх</div>
          <div style={{ padding: '20px 35px' }}>
            <Form name="new_banner" form={addBannerFormRef} onFinish={addBanner}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    className="mb-3"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Нэрээ оруулна уу!',
                      },
                    ]}
                  >
                    <Input className="sb-input sb-shadow" placeholder="Нэр*" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Upload {...uploadProps}>
                      <FontAwesomeIcon icon={faUpload} size="2x" style={{ color: '#11427f', marginRight: '15px' }} />
                      <span style={{ fontSize: '18px' }}>Зураг оруулах</span>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)}>
                      Идэвхитэй
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Editor
                    apiKey="2btxhsu7kectzuz2hbp2i8t9mrnm752pjo66yfcmyaecint6"
                    value={bannerContent}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link charmap print preview anchor',
                        'searchreplace visualblocks fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                    }}
                    onEditorChange={(content) => setBannerContent(content)}
                  />
                </Col>
                <Col span={24}>
                  <Form.Item className="mt-3">
                    <Button type="primary" className="w-100 btn-sb btn-sb-primary sb-shadow" form="new_banner" htmlType="submit" loading={loading}>
                      Хадгалах
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Col>
      <Modal
        open={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        title="Баннер мэдээлэл"
        className="sb-modal sb-modal-kiosk"
        width={700}
        destroyOnClose
        centered
        maskStyle={{
          background: '#11427FB9',
        }}
        footer={[
          <Row justify="center" key="modal" gutter={[12, 24]}>
            <Col span={12}>
              <Button
                className="btn-sb btn-sb-primary w-100"
                onClick={() => {
                  editBanner()
                }}
              >
                Хадгалах
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form name="edit_banner" form={editBannerFormRef}>
          <Form.Item className="mb-3" name="id" hidden={true}>
            <Input />
          </Form.Item>
          <Row>
            <Col span={24}>
              <Form.Item
                className="mb-3"
                name="Name"
                rules={[
                  {
                    required: true,
                    message: 'Нэрээ оруулна уу!',
                  },
                ]}
              >
                <Input className="sb-input sb-shadow" placeholder="Нэр*" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="IsActive">
                <Checkbox.Group>
                  <Row>
                    <Col span={8}>
                      <Checkbox value="active" className="sb-checkbox">
                        Идэвхитэй
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="HTMLContent" className="mb-0">
                <Editor
                  apiKey="2btxhsu7kectzuz2hbp2i8t9mrnm752pjo66yfcmyaecint6"
                  init={{
                    height: 250,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link charmap print preview anchor',
                      'searchreplace visualblocks fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default Banners
