import React, { useContext } from "react";
import { UserInfoContext } from "../../../pages/admin/users/sender/details";

import { Row, Col, Form, Input } from "antd";

const UserInfo = () => {
  const { formRef, editSenderInfo } = useContext(UserInfoContext) || {};

  return (
    <Form name="user-details" form={formRef} onFinish={editSenderInfo}>
      <Row gutter={[16, 8]}>
        <Col span={5}>
          <Form.Item name="firstName" label="Нэр">
            <Input placeholder="Нэр" className="sb-input sb-shadow" />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="lastName" label="Овог">
            <Input placeholder="Овог" className="sb-input sb-shadow" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="phoneNumber" label="Утас">
            <Input placeholder="Утас" className="sb-input sb-shadow" />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item name="address" label="Хаяг">
            <Input placeholder="Хаяг" className="sb-input sb-shadow" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UserInfo;
