import React, { useEffect, useState } from 'react'

import client from '../../../api/client'

import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, Modal, Form, Input, DatePicker, Select } from 'antd'
import { isOwner } from '../../../api/auth'

export default function CreateShipment(props) {
  const [branches, setBranches] = useState([])

  useEffect(() => {
    client.get('/api/lookup/branches').then((res) => {
      if (res.status === 200) {
        setBranches(res.data.map((b) => ({ value: b.id, label: b.name })))
      }
    })
  }, [])

  return (
    <Modal
      title="Шинэ богц"
      open={props.modalVisible}
      onCancel={() => props.closeModal()}
      className="sb-modal"
      width={700}
      destroyOnClose
      centered
      footer={[
        <Row justify="center" key="add-shipment-modal">
          <Col span={18}>
            <Button className="btn-sb btn-sb-primary w-100" form="add-shipment" htmlType="submit" loading={props.apiLoading}>
              Бүртгэх
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Form name="add-shipment" form={props.formRef} onFinish={props.createShipment}>
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item name="date" label="Огноо" rules={[{ required: true, message: 'Огноо оруулна уу!' }]}>
              <DatePicker className="sb-datepicker sb-shadow" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="shipmentType" label="Төрөл" rules={[{ required: true, message: 'Төрөл оруулна уу!' }]}>
              {isOwner() ? (
                <Select
                  placeholder="Төрөл"
                  className="sb-select sb-shadow"
                  popupClassName="sb-select-dropdown"
                  options={[
                    { value: 'Air', label: 'Агаар' },
                    { value: 'Ground', label: 'Газар' },
                    { value: 'Express', label: 'Express' },
                    { value: 'Container', label: 'Чингэлэг' },
                  ]}
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} size="sm" className="me-2 base-color" />}
                />
              ) : (
                <Select
                  placeholder="Төрөл"
                  className="sb-select sb-shadow"
                  popupClassName="sb-select-dropdown"
                  options={[
                    { value: 'Air', label: 'Агаар' },
                    { value: 'Ground', label: 'Газар' },
                    { value: 'Express', label: 'Express' },
                  ]}
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} size="sm" className="me-2 base-color" />}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="shipmentStatus" label="Статус" rules={[{ required: true, message: 'Статус оруулна уу!' }]}>
              <Select
                placeholder="Статус"
                className="sb-select sb-shadow"
                popupClassName="sb-select-dropdown"
                options={props.shipmentStatuses}
                suffixIcon={<FontAwesomeIcon icon={faSortDown} size="sm" className="me-2 base-color" />}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="branchId" label="Салбар" rules={[{ required: true, message: 'Салбар сонгоно уу!' }]}>
              <Select
                placeholder="Салбар"
                className="sb-select sb-shadow"
                popupClassName="sb-select-dropdown"
                options={branches}
                suffixIcon={<FontAwesomeIcon icon={faSortDown} size="sm" className="me-2 base-color" />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label="Тайлбар">
              <Input.TextArea placeholder="Тайлбар" className="sb-input sb-shadow" rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
