import React, { useEffect } from 'react'

import { Row, Col, Button, Modal, Form, Input } from 'antd'

const ChangePinModal = (props) => {
  useEffect(() => {
    return () => props.formRef.resetFields()
  }, [props.formRef])

  return (
    <Modal
      title="Пин код солих"
      open={props.modalVisible}
      onCancel={props.closeModal}
      className="sb-modal"
      width={400}
      destroyOnClose
      centered
      footer={[
        <Row justify="center" key="change-pin-code">
          <Col span={24}>
            <Button className="btn-sb btn-sb-primary w-100" form="change-pin" htmlType="submit">
              Солих
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Form name="change-pin" form={props.formRef} onFinish={props.onSubmit}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              name="password"
              className="form-item"
              rules={[
                {
                  required: true,
                  message: 'Шинэ пин код оруулна уу!',
                },
                {
                  len: 6,
                  message: 'Пин код 6-оронтой байх шаардлагатай',
                },
              ]}
            >
              <Input placeholder="Шинэ пин код" type="number" className="sb-input sb-shadow" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: 'Пин кодоо давтаж оруулна уу!',
                },
                {
                  len: 6,
                  message: 'Пин код 6-оронтой байх шаардлагатай',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Оруулсан 2 пин код өөр байна!')
                  },
                }),
              ]}
            >
              <Input placeholder="Пин кодоо давтан оруулна уу" type="number" className="sb-input sb-shadow" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ChangePinModal
